import { NAVIGATION_ICON } from "constants/app.constant";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileUpload = ({ onFilesChange, fileType, label, error }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFiles = useCallback(
    (files) => {
      const filesWithPreview = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setUploadedFiles(filesWithPreview);

      if (onFilesChange) {
        onFilesChange(filesWithPreview);
      }
    },
    [uploadedFiles, onFilesChange]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: fileType,
  });

  return (
    <div className="mb-7">
      <div>
        <label className="text-[14px] text-gray-500 font-bold ">{label}</label>
        <div
          {...getRootProps()}
          className="border border-dashed border-green-400 flex items-center justify-center cursor-pointer w-full bg-green-100 text-green-500 rounded-md my-1"
        >
          <input {...getInputProps()} />
          <div className="cursor-pointer p-2 flex gap-2 justify-center items-center w-full h-full font-bold">
            <div className="text-[20px]">{NAVIGATION_ICON.FILE_UPLOAD}</div>
            <div> Browse here</div>
          </div>
        </div>
        {error ? <p className="text-red-500 text-[12px]">{error}</p> : ""}
      </div>

      <div>
        {uploadedFiles.length ? (
          <div className="mt-2 relative border w-full flex gap-4 items-center rounded-md overflow-hidden">
            <div
              className="absolute right-1 text-[10px] top-1 cursor-pointer flex items-center justify-center "
              onClick={() => {
                setUploadedFiles(
                  uploadedFiles.filter((item) => item !== uploadedFiles[0])
                );
                onFilesChange([])
              }}
            >
              {NAVIGATION_ICON.CROSS_BUTTON}
            </div>
            {uploadedFiles[0].type.startsWith("image/") ? (
              <div className="w-[60px] h-[60px]">
                <img
                  src={uploadedFiles[0].preview}
                  alt={uploadedFiles[0].name}
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-[60px] h-[60px] border-r flex justify-center items-center text-[34px] text-green-500">
                {NAVIGATION_ICON.SONG}
              </div>
            )}

            <div>
              <p className="w-40 truncate font-bold">{uploadedFiles[0].name}</p>
              <p className="text-gray-300">
                {(uploadedFiles[0].size / 1048576).toFixed(2)} MB
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FileUpload;
