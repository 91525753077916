import React, { useState } from "react";
import TableSearchBar from "../TableSearchBar";
import { Button } from "components/ui";
import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";

const FilterBar = ({
  search,
  FilterMenu,
  setSearch,
  setFilterValue,
  onFilterClick,
  motionFilters,
  handleBtnClick,
  btnLabel,
  placeholder,
  uploadSongsBtnLabel,
  handleUploadBtn,
}) => {

  return (
    <div className="flex flex-wrap w-full items-center  justify-between">
      <div className="flex w-full gap-4  md:w-8/12 lg:w-8/12 xl:w-8/12 ">
        {placeholder && (
          <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <TableSearchBar
              search={search}
              placeholder={placeholder}
              setSearch={setSearch}
              SearchBarInfo={FilterMenu?.isSearch?.toolTipText}
            />
          </div>
        )}
        {/* {FilterMenu?.isCalendarFilter && (
          <div className="flex w-full 'sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <DatePicker.DatePickerRange
              size="sm"
              dateViewCount={2}
              value={[
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey1],
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey2],
              ]}
              placeholder={FilterMenu?.isCalendarFilter?.placeholder}
              onChange={(dateArray) => {
                setFilterValue({
                  ...filterValue,
                  [FilterMenu?.isCalendarFilter?.filterKey1]: dateArray[0],
                  [FilterMenu?.isCalendarFilter?.filterKey2]: dateArray[1],
                });
              }}
            />
          </div>
        )} */}
      </div>
      <div className="flex sm:w-full   sm:justify-end gap-2 sm:mt-0 mt-3 overflow-x-auto ">
        {FilterMenu?.isFilterButton && (
          <Button
            size="sm"
            className={` ${motionFilters && "text-[#22C55E]"}`}
            onClick={onFilterClick}
            icon={<CiFilter color={motionFilters && "#22C55E"} />}
          >
            Filter
          </Button>
        )}
        {FilterMenu?.isResetButton && (
          <Button
            size="sm"
            onClick={() => {
              setFilterValue("");
              setSearch("");
            }}
            icon={<GrPowerReset />}
          >
            Reset
          </Button>
        )}

        {/* {tableData && (
          <Button variant="solid" size="md" className=" ml-2">
            <div className="flex justify-center items-center">
              <SongPlayButton
                style={{ color: "white" }}
                link={IMAGE_BASE_URL + tableData[0]?.songId?.url}
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                songId={tableData[0]?.songId?._id}
              />
            </div>
          </Button>
        )} */}
        {uploadSongsBtnLabel && (
          <Button variant="solid" size="sm" onClick={handleUploadBtn}>
            {uploadSongsBtnLabel}
          </Button>
        )}
        {btnLabel && (
          <Button
            variant="solid"
            size="sm"
            onClick={handleBtnClick}
          >
            {btnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
export default FilterBar;
