import React from "react";
import { Checkbox } from "components/ui";
import {
  TABLE_ACTION_KEYS,
  ACTIVE_INACTIVE_STATUS,
} from "constants/app.constant";

const ActiveInActiveCheckbox = ({
  dataObj,
  onActionHandle,
  data,
  dataKey,
  rowdata,
}) => {
  const activeValue = data.find((item, i) => item.key === dataObj);

  return (
    <>
      <Checkbox
        checked={activeValue?.value}
        onChange={(e) => onActionHandle(dataKey, rowdata)}
      />
    </>
  );
};
export default ActiveInActiveCheckbox;
