export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const REQUEST_HEADER_LANG_KEY = "language";
export const SML_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";

export const APIS = {
  AUTH: {
    LOGIN: "/admin/signin",
    RESET_PASSWORD: "/admin/resetPassword",
  },

  USERS: {
    EDIT_USER: "admin/editUser",
  },
  PRODUCER: {
    GET_PRODUCER: "/producer/get",
    CREATE_PRODUCER: "/producer/create",
    EDIT_PRODUCER: "/producer/edit",
  },
  ADVERTISEMENT_LIST: "advertisement/get",
  ADVERTISEMENT: {
    CREATE_ADVERTISEMENT: "advertisement/create",
    EDIT_ADVERTISEMENT: "advertisement/edit",
  },
  PLAYLIST_LIST: "playlist/get",
  PLAYLIST: {
    EDIT_PLAYLIST: "playlist/edit",
    CREATE_PLAYLIST: "playlist/create",
    ADD_PLAYLIST: "playlist/addSong",
  },
  SONG_CATEGORIES_LIST: "/songCategory/get",
  SONG_CATEGORIES: {
    CREATE_CATEGORIES: "/songCategory/create",
    EDIT_CATEGORIES: "/songCategory/edit",
  },
  SONG_LIST: "/song/get",
  SONG: {
    EDIT_SONG: "/song/edit",
    UPLOAD_SONG: "/song/upload",
    UPLOAD_BULK: "/song/uploadBulk",
    UPLOAD_EXCEL: "/song/bulkEdit",
    DOWNLOAD_EXCEL: "song/export",
  },
  ARTIST_LIST: "/artist/get",
  ARTISTS: {
    CREATE_ARTIST: "/artist/create",
    EDIT_ARTIST: "/artist/edit",
  },

  ADMIN: {
    CREATE_ADMIN: "/admin/create",
    DELETE_ADMIN: "/admin/delete",
    EDIT_ADMIN: "/admin/editAdmin",
  },

  DEFAULT: {
    DEFAULT_GET: "/appDefaultData/get",
    DEFAULT_EDIT: "/appDefaultData/edit",
  },

  COMMENTS: {
    GET_COMMENTS: "/songComment/get",
    EDIT_COMMENTS: "/songComment/edit",
  },

  ADMIN_LIST: "/admin/getAdmin",
  LIST_USERS: "/admin/getUser",
  DASHBOARD: "/admin/dashboardDetails",
  CHANGE_PASSWORD: "/crmapi/api/UserLogin/UserChangePassword",

  STORY: {
    GET_STORY: "/story/get",
    CREATE_STORY: "/story/create",
    EDIT_STORY: "/story/edit",
  },
  STORY_CATEGORY: {
    GET_STORY_CATEGORY: "/storyCategory/get",
    CREATE_STORY_CATEGORY: "/storyCategory/create",
    EDIT_STORY_CATEGORY: "/storyCategory/edit",
  },
  STORY_SUB_CATEGORY: {
    GET_STORY_SUBCATEGORY: "/storySubCategory/get",
    CREATE_STORY_SUBCATEGORY: "/storySubCategory/create",
    EDIT_STORY_SUBCATEGORY: "/storySubCategory/edit",
  },
  LIVE_PLAYLIST: {
    GET_LIVEPLAYLIST: "/liveSongPlaylist/get",
    CREATE_LIVEPLAYLIST: "/liveSongPlaylist/create",
    EDIT_LIVEPLAYLIST: "/liveSongPlaylist/edit",
  },
};
