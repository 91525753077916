import React from "react";
import { Button } from "components/ui";
import { hasPermission } from "utils/utils";


const PageHeader = ({ title, buttons = [], icon, pageIdentifier }) => {

  return (
    <div className="flex items-center flex-wrap   justify-between mb-5">
      <div className="capitalize font-bold text-black md:text-2xl xs:text-base sm:text-xl mb-4 flex">
        <span className="mr-1" style={{ marginTop: "2px" }}>
          {icon}
        </span>
        <span>{title}</span>
      </div>
      <div className="flex">
        {buttons?.map((btn) => {
          const component = (
            <Button
              key={btn?.label[0]}
              size="sm"
              variant={btn?.variant || "solid"}
              type={btn?.type || "button"}
              onClick={(e) => btn?.onClick?.(btn?.key, e)}
              className="ml-2 capitalize"
              icon={btn.icon}
              disabled={btn?.disabled}
              loading={btn?.loading}
            >
              {btn.label}
            </Button>
          );
          if (btn?.key && pageIdentifier) {
            return hasPermission(btn?.key, pageIdentifier) ? component : null;
          } else return component;
        })}

       
      </div>
    </div>
  );
};

export default PageHeader;
