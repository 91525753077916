import React, { useCallback, useState } from "react";
import { Input, Tooltip } from "components/ui";
import { HiOutlineSearch } from "react-icons/hi";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { debounce } from "lodash";

const TableSearchBar = ({ SearchBarInfo, placeholder, setSearch }) => {
  const [searchText, setSearchText] = useState("");
  const debounceFn = useCallback(debounce(setSearch, 300), []);
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    debounceFn(e.target.value);
  };

  return (
    <Input
      value={searchText}
      size="sm"
      placeholder={placeholder}
      prefix={<HiOutlineSearch className="text-lg" />}
      suffix={
        <Tooltip title={SearchBarInfo}>
          <BsFillInfoCircleFill className="text-lg" />
        </Tooltip>
      }
      onChange={handleInputChange}
    />
  );
};

export default TableSearchBar;
