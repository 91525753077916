import { Notification, toast } from "components/ui";
import { NAVIGATION_ICON } from "constants/app.constant";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardButton = ({ text }) => {
  return (
    <div>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          toast.push(
            <Notification title="Success" type="success">
              copied successfully !
            </Notification>
          );
        }}
        className="cursor-pointer w-6 h-6 text-green-500"
      >
        {NAVIGATION_ICON.COPY_ICON}
      </CopyToClipboard>
    </div>
  );
};

export default CopyToClipboardButton;
