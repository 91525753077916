import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppLanguage } from "store/theme/themeSlice";

const ToggleButton = ({ initialState = false, onToggle, customStyle }) => {
  const [isToggled, setIsToggled] = useState(initialState);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.theme.lang);

  const handleToggle = () => {
    const newState = !isToggled;

    setIsToggled(newState);
    dispatch(setAppLanguage(newState ? "Hi" : "En"));

    // if (onToggle) onToggle(newState);
  };

  return (
    <button
      onClick={handleToggle}
      className={`w-[40px] h-6 rounded-full ${
        lang === "Hi" ? "bg-green-500" : "bg-gray-500"
      } relative ${customStyle}`}
    >
      <span
        className={`block w-[20px] h-[20px] bg-white rounded-full shadow-md transform duration-300 ease-in-out ${
          lang === "Hi" ? "translate-x-5" : "translate-x-0"
        }`}
      />
    </button>
  );
};

export default ToggleButton;
