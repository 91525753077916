import React, { useRef, useEffect } from "react";
import { NAVIGATION_ICON } from "constants/app.constant";

const SongPlayButton = ({ link, songId, currentAudio, setCurrentAudio ,style}) => {
  const audioRef = useRef(null);
  const isPlaying = currentAudio === songId;

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setCurrentAudio(null); 
    } else {
      setCurrentAudio(songId); 
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error("Error playing the audio:", error);
        });
      } else {
        audioRef.current.pause(); 
        audioRef.current.currentTime = 0; 
      }
    }
  }, [isPlaying]);

  return (
    <div className="player-container">
      <audio ref={audioRef}>
        <source src={link} type="audio/mp3" />
      </audio>

      <button onClick={togglePlay} className="text-green-500" style={{...style}}>
        {isPlaying ? NAVIGATION_ICON.PAUSE : NAVIGATION_ICON.PLAY_ICON}
      </button>
    </div>
  );
};

export default SongPlayButton;
