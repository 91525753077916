import { Button, Dialog } from "components/ui";
import { NAVIGATION_ICON } from "constants/app.constant";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({
  handleFiles, 
  fileType,
  isOpen,
  ToggleModal,
  modalWidth,
  closeButtonColor,
  loading,
  isMulti = false,
  icon,
}) {
  const [uploadedFile, setUploadedFiles] = useState([]); 

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFiles(acceptedFiles); 
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMulti, 
    accept: fileType,
  });

  const handleSubmit = () => {
    if (uploadedFile.length > 0) {
      handleFiles(uploadedFile); 
      ToggleModal()
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={ToggleModal}
      onRequestClose={ToggleModal}
      width={modalWidth}
      contentClassName="p-4"
      closeButtonColor={closeButtonColor}
    >
      <div
        {...getRootProps()}
        className={`border-2 border-dashed p-10 h-[200px] mb-4 rounded-md flex flex-col items-center justify-center ${
          isDragActive ? "border-green-400 bg-green-100" : "border-gray-400"
        }`}
      >
        <input {...getInputProps()} />
        <p className="text-gray-500">Drag & Drop files here</p>
        <p className="text-gray-400">or click to select files</p>
      </div>

      {/* Show uploaded files */}
      {uploadedFile.length > 0 && (
        <div className="my-4 overflow-y-auto h-[200px] ">
          {uploadedFile.map((file, index) => (
            <div
              key={index}
              className="flex relative items-center gap-2 mb-2 border"
            >
              <div className="w-[60px] text-green-400 h-[60px] border-r flex items-center justify-center text-[20px]" >
                {icon}
              </div>
              <div>
                <p className="font-bold">{file.name}</p>
                <p className="text-gray-500">
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
              <div
                className="absolute right-1 text-[10px] top-1 cursor-pointer flex items-center justify-center hover:bg-gray-100 w-[30px] rounded-md h-[30px]"
                onClick={() =>
                  setUploadedFiles(uploadedFile.filter((item) => item !== file))
                }
              >
                {NAVIGATION_ICON.CROSS_BUTTON}
              </div>
            </div>
          ))}
        </div>
      )}

      <div>
        <Button
          variant="solid"
          size="md"
          className="mr-2"
          loading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button variant="solid" size="md" onClick={ToggleModal}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default Dropzone;
