import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_LANG_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
import { toast, Notification } from "components/ui";
import { getState } from "../store";

const unauthorizedCode = [401];

const showErrorToast = (error) => {
  toast.push(
    <Notification type="danger" closable>
      {error}
    </Notification>
  );
};

const BaseService = axios.create({
  timeout: 10 * 60000,
  baseURL: appConfig.apiBaseUrl,
});

BaseService.interceptors.request.use(
  (config) => {
    if (navigator?.onLine === true) {
      const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
      const persistData = deepParseJson(rawPersistData);
      const accessToken = persistData.auth.session.token;
      if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${accessToken}`;
        config.headers[REQUEST_HEADER_LANG_KEY]=`${getState().theme.lang}`
      }      
      return config;
    } else {
      showErrorToast("Please enable internet");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
BaseService.interceptors.response.use(
  
  (response) => {    
    if (response?.data.statusCode === 200 || response?.data.statusCode === 202) {
      return response;
    } else {
      if (response && unauthorizedCode.includes(response?.data?.statusCode)) {
        store.dispatch(onSignOutSuccess());
      } else {
        showErrorToast(response?.data?.message || "Something went wrong");
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    const { response } = error;
    showErrorToast(response?.data?.message || response?.statusText || "Server error");
    if (response && unauthorizedCode.includes(response?.status)) {
      store.dispatch(onSignOutSuccess());
    }
    return Promise.reject(error);
  }
);

export default BaseService;
