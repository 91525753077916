import { APIS } from "constants/api.constant";
import { postApi, getApi } from "./CommonService";
import appConfig from "configs/app.config";
import { toast, Notification } from "components/ui";
import moment from "moment";

export const getAdminListing = async ({ page, limit, search, status }) => {
  return await getApi(APIS.ADMIN_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getPlaylistListing = async ({ page, limit, search, status }) => {
  return await getApi(APIS.PLAYLIST_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getAdvertisment = async ({ page, limit, search, status }) => {
  return await getApi(APIS.ADVERTISEMENT_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getSongCategoryList = async ({ page, limit, search, status }) => {
  return await getApi(APIS.SONG_CATEGORIES_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getStories = async ({
  page,
  limit,
  search,
  subCategoryId,
  categoryId,
  status,
}) => {
  return await getApi(APIS.STORY.GET_STORY, {
    page: page + 1,
    limit: limit,
    ...(search && { search: search }),
    ...(subCategoryId && { storySubCategoryId: subCategoryId }),
    ...(categoryId && { storyCategoryId: categoryId }),
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getStoryCategory = async ({ page, limit, search, status }) => {
  return await getApi(APIS.STORY_CATEGORY.GET_STORY_CATEGORY, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getStorySubCategory = async ({
  page,
  limit,
  search,
  id,
  status,
}) => {
  return await getApi(APIS.STORY_SUB_CATEGORY.GET_STORY_SUBCATEGORY, {
    page: page + 1,
    limit: limit,
    search: search,
    storyCategoryId: id,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getSongList = async ({
  page,
  limit,
  search,
  artistId,
  songCategoryId,
  status,
}) => {
  return await getApi(APIS.SONG_LIST, {
    page: page + 1,
    limit: limit,
    status: status,
    ...(search && { search: search }),
    ...(artistId && { artistId: artistId }),
    ...(songCategoryId && { songCategoryId: songCategoryId }),
  }).then((res) => {
    return res;
  });
};

export const getArtistsList = async ({ page, limit, search, status }) => {
  return await getApi(APIS.ARTIST_LIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};
export const getProducerList = async ({ page, limit, search, status }) => {
  return await getApi(APIS.PRODUCER.GET_PRODUCER, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getLivePlaylist = async ({ page, limit, search, status }) => {
  return await getApi(APIS.LIVE_PLAYLIST.GET_LIVEPLAYLIST, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getSongComments = async ({
  page,
  limit,
  search,
  songId,
  status,
}) => {
  return await getApi(APIS.COMMENTS.GET_COMMENTS, {
    page: page + 1,
    limit: limit,
    search: search,
    songId: songId,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getUserListing = async ({ page, limit, search, status }) => {
  return await getApi(APIS.LIST_USERS, {
    page: page + 1,
    limit: limit,
    search: search,
    status: status,
  }).then((res) => {
    return res;
  });
};

// export const getUserListing = async ({
//   pageIndex,
//   pageSize,
//   totalPages,
//   filterText,
//   userType,
//   otherObj,
// }) => {
//   return await postApi(APIS.LIST_USERS, {
//     pageIndex,
//     pageSize,
//     totalPages,
//     filterText,
//     userType: [userType],
//     ...otherObj,
//   }).then((res) => {
//     return res;
//   });
// };

export const addUpdateuser = async (data) => {
  return await postApi(APIS.UPDATE_USER, { ...data }).then((res) => {
    return res;
  });
};

export const getUserById = async ({ id: id }) => {
  return await getApi(APIS.USER_BY_ID, { id: Number(id) }).then((res) => res);
};

export const getCommonAPIListing = async ({
  api,
  pageIndex,
  pageSize,
  totalPages,
  filterText,
  otherObj,
}) => {
  return await postApi(api, {
    pageIndex,
    pageSize,
    totalPages,
    filterText,
    ...otherObj,
  }).then((res) => {
    return res;
  });
};

export const excelExportCommon = async (
  apiName,
  token,
  bodyData,
  fileName,
  setExportLoading
) => {
  setExportLoading(true);
  fetch(appConfig.apiBaseUrl + apiName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(bodyData),
  })
    .then((response) => {
      if (!response.ok) {
        toast.push(
          <Notification type="error">
            `HTTP error! Status: ${response.status}`
          </Notification>
        );
      }
      return response.blob(); // Get the file as a Blob
    })
    .then((blob) => {
      // Create a temporary anchor element
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}_${moment().format("DD-MMM-YYYY")}.xlsx`; // File name to download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.push(
        <Notification type="success">File downloaded successfully</Notification>
      );
    })
    .catch((error) => {
      toast.push(
        <Notification type="error">
          `Error downloading Excel file: {error}`
        </Notification>
      );
    })
    .finally(() => {
      setExportLoading(false);
    });
};
